import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filters", "toggleButton"];

  connect() {
    this.loadState();
  }

  currentPage() {
    return window.location.pathname.split("/").pop();
  }

  toggle() {
    this.filtersTarget.classList.toggle("d-none");
    this.updateButtonText();
    this.saveState();
  }

  loadState() {
    const isHidden =
      localStorage.getItem(`${this.currentPage}-filtersHidden`) === "true";
    if (isHidden) {
      this.filtersTarget.classList.add("d-none");
      this.toggleButtonTarget.textContent = "Show";
    } else {
      this.filtersTarget.classList.remove("d-none");
      this.toggleButtonTarget.textContent = "Hide";
    }
  }

  saveState() {
    const isHidden = this.filtersTarget.classList.contains("d-none");
    localStorage.setItem(`${this.currentPage}-filtersHidden`, isHidden);
  }

  updateButtonText() {
    if (this.filtersTarget.classList.contains("d-none")) {
      this.toggleButtonTarget.textContent = "Show";
    } else {
      this.toggleButtonTarget.textContent = "Hide";
    }
  }
}
