import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['amount', 'msisdn', 'typeOf', 'withdrawFee']

  connect() {
    this.calculateFee()
  }

  calculateFee() {
    const amount = this.amountTarget.value
    const msisdn = this.msisdnTarget.value
    const typeOf = this.typeOfTarget.value
    const url = this.amountTarget.dataset.url

    if (typeOf === 'payout' && amount) {
      fetch(`${url}?amount=${amount}&msisdn=${msisdn}`, {
        headers: {
          'X-CSRF-Token': document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content'),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then((data) => {
          this.withdrawFeeTarget.innerHTML = `Withdraw Fee: ${data.withdraw_fee}`
        })
    } else {
      this.withdrawFeeTarget.innerHTML = ''
    }
  }
}
