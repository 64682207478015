import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
    static targets = ["msisdn", "customerName", "networkCustomerName", "productCode", "locationId", "bundleCode"]

    #setFailureState(){
        this.customerNameTarget.textContent = "No customer name found for this phone number."
        this.customerNameTarget.style.color = "grey"
    }

    #setSuccessState(customer_name){
        this.customerNameTarget.textContent = "Customer name: "+ customer_name
        this.networkCustomerNameTarget.value = customer_name
        this.customerNameTarget.style.color = "green"
    }

    validateOnNetworkMobileMoney() {
        const msisdn = this.msisdnTarget.value
        this.#setFailureState()
        fetch(`/user/mobile_money_requests/validate_on_network?msisdn=${msisdn}`)
            .then(res => res.json())
            .then(({customer_name}) => {
                if (customer_name) this.#setSuccessState(customer_name);
            }).catch(e => e)
    }

    validateOnNetworkProduct(){
        const msisdn = this.msisdnTarget.value
        const productCode = this.productCodeTarget.value
        const bundleCode = this.bundleCodeTarget.value
        const locationId = this.locationIdTarget.value
        if(!msisdn) return;
        this.#setFailureState()
        fetch(`/user/product_purchase_requests/validate_on_network?msisdn=${msisdn}&product_code=${productCode}&location_id=${locationId}&bundle_code=${bundleCode}`)
            .then(res => res.json())
            .then(({customer_name}) => {
                if (customer_name) this.#setSuccessState(customer_name);
            }).catch(e => e)
    }

}
