import { Controller } from "@hotwired/stimulus"
import { 
  Chart,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PointElement,
  Filler
} from 'chart.js'

// Register required Chart.js components
Chart.register(
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PointElement,
  Filler
)

/**
 * Dashboard Charts Controller
 * @class DashboardChartsController
 * @extends Controller
 * @description Manages the initialization and display of dashboard charts
 */
export default class extends Controller {
  connect() {
    this.initializeCharts()
  }

  initializeCharts() {
    const chartConfig = {
      type: 'line',
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'category',
            display: false,
            grid: {
              display: false
            }
          },
          y: {
            type: 'linear',
            display: false,
            grid: {
              display: false
            }
          }
        },
        elements: {
          line: {
            tension: 0.4,
            borderWidth: 2
          },
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4
          }
        }
      }
    };

    // Provider Balance Chart
    new Chart(document.getElementById('provider-balance-chart'), {
      ...chartConfig,
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
          label: 'Provider Balance',
          backgroundColor: 'rgba(77, 171, 77, 0.1)',
          borderColor: '#4DAB4D',
          data: [65, 59, 84, 84, 51, 55, 40],
          fill: true
        }]
      }
    });

    // customer-balance-chart
    new Chart(document.getElementById('customer-balance-chart'), {
      ...chartConfig,
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
          label: 'Customer Balance',
          backgroundColor: 'rgba(54, 162, 235, 0.1)',
          borderColor: '#36A2EB',
          data: [65, 59, 84, 84, 51, 55, 40],
          fill: true
        }]
      }
    });

    // success-rate-chart
    new Chart(document.getElementById('success-rate-chart'), {
      ...chartConfig,
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
          label: 'Success Rate',
          backgroundColor: 'rgba(77, 171, 77, 0.1)',
          borderColor: '#4DAB4D',
          data: [65, 59, 84, 84, 51, 55, 40],
          fill: true
        }]
      }
    });

    // pending-transactions-chart
    new Chart(document.getElementById('pending-transactions-chart'), {
      ...chartConfig,
      data: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [{
          label: 'Pending Transactions',
          backgroundColor: 'rgba(255, 99, 132, 0.1)',
          borderColor: '#FF9F40',
          data: [65, 59, 84, 84, 51, 55, 40],
          fill: true
        }]
      }
    }); 
  }
} 