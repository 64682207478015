import { Controller } from "@hotwired/stimulus";
/**
 * Controller class for handling single checkbox functionality.
 */
export default class extends Controller {
  static targets = ["checkbox"];

  /**
   * Connects the controller to the DOM elements.
   */
  connect() {
    /**
     * Event listener for the "change" event on checkboxes.
     * @param {Event} event - The change event object.
     */
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.addEventListener("change", this.checkOnlyOne.bind(this));
    });
  }

  /**
   * Handles the logic to allow only one checkbox to be checked.
   * @param {Event} event - The change event object.
   */
  checkOnlyOne(event) {
    if (event.target.checked) {
      this.checkboxTargets.forEach((checkbox) => {
        if (checkbox !== event.target) checkbox.checked = false;
      });
    }
  }
}
