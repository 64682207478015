import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["slide"];

  connect() {
    this.hide();
  }

  toggle() {
    this.slideTarget.classList.toggle('d-none');
  }

  hide() {
    this.slideTarget.classList.add('d-none');
  }

  show() {
    this.slideTarget.classList.remove('d-none');
  }
}
