import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal", "backdrop"]

  show(event) {
    event.preventDefault();
    this.modalTarget.classList.add('show');
    this.modalTarget.style.display = "block";
    this.modalTarget.setAttribute('aria-hidden', 'false');
    document.body.classList.add('modal-open');

    // Show the backdrop (dark background)
    this.backdropTarget.classList.add('show');
    this.backdropTarget.style.display = "block";
  }

  hide(event) {
    event.preventDefault();
    // Close modal
    this.modalTarget.classList.remove('show');
    this.modalTarget.style.display = "none";
    this.modalTarget.setAttribute('aria-hidden', 'true');
    document.body.classList.remove('modal-open');

    // Hide the backdrop
    this.backdropTarget.classList.remove('show');
    this.backdropTarget.style.display = "none";
  }

  backdropClick(event) {
    if (event.target === this.backdropTarget) {
      this.hide(event);
    }
  }
}
