import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["productInput"];

  static values = {
    paymentType: String
  }

  connect() {
    this.bundles = $("#beneficiary_payment_detail_bundle_id").html();
    this.initTransferTypeInput(this.paymentTypeValue)
  }

  onProductChange() {
    this.initBundlesInput(this.productInputTarget.selectedOptions[0].text);
  }

  onTransferTypeChange() {
    this.initBundlesInput(this.productInputTarget.selectedOptions[0].text);
    this.initTransferTypeInput(this.transferTypeInputTarget.selectedOptions[0].value)
  }

  hideBanks() {
    const productInput = $("#beneficiary_payment_detail_product_id");
    productInput.find('option').each((index, option) => {
      const optionText = option.text.toLowerCase();
      if (!optionText.includes('transfer')) {
        $(option).attr('hidden', false);
      } else {
        $(option).attr('hidden', true);
      }
    });
  }
  
  hideNonBanks() {
    const productInput = $("#beneficiary_payment_detail_product_id");
    productInput.find('option').each((index, option) => {
      const optionText = option.text.toLowerCase();
      if (!optionText.includes('transfer')) {
        $(option).attr('hidden', true);
        $("label[for='beneficiary_payment_detail_product_id']").text('Banks');
      } else {
        $(option).attr('hidden', false);
      }
    });
  }

  initTransferTypeInput(type){
    
    if(["mobile_money_payout","bank_payout"].includes(type) ){
      $("#beneficiary_payment_detail_product_id").parent().hide();
      $("#beneficiary_payment_detail_bundle_id").parent().hide();
      $("#beneficiary_payment_detail_contact_phone").parent().hide();
      $("#beneficiary_payment_detail_amount").parent().show();
    } else{
      this.hideBanks()
      this.showContactAndPhoneField()
    }
  }

  showContactAndPhoneField(){
    $("#beneficiary_payment_detail_product_id").parent().show();
    $("#beneficiary_payment_detail_contact_phone").parent().show();
  }

  initBundlesInput(product) {
    
    let escapedProduct = product.replace(
      /([ #;&,.+*~\':"!^$[\]()=>|\/@])/g,
      "\\$1"
    );
    let options = $(this.bundles)
      .filter(`optgroup[label="${escapedProduct}"]`)
      .html();
    if (options) {

      $("#beneficiary_payment_detail_amount").parent().hide();
      $("#beneficiary_payment_detail_bundle_id").html(options);
      $("#beneficiary_payment_detail_bundle_id").parent().show();
      
    } else {
      $("#beneficiary_payment_detail_bundle_id").parent().hide();
      $("#beneficiary_payment_detail_amount").parent().show();
      $("#beneficiary_payment_detail_bundle_id").empty();
    }
  }
}
