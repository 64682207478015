import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['menu'];

  connect() {
    this.closeMenu();
  }

  toggleMenu() {
    this.menuTarget.classList.toggle('hidden');
  }

  closeMenu() {
    this.menuTarget.classList.add('hidden');
  }
}
