import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["productInput", "transferTypeInput"];

  connect() {
    this.bundles = $("#contact_bundle_id").html();
    this.initTransferTypeInput(this.transferTypeInputTarget.selectedOptions[0].value)
    this.initBundlesInput(this.productInputTarget.selectedOptions[0].text);
    this.initNwscLocationsInput(this.productInputTarget.selectedOptions[0].text);
  }

  onProductChange() {
    this.initBundlesInput(this.productInputTarget.selectedOptions[0].text);
    this.initNwscLocationsInput(this.productInputTarget.selectedOptions[0].text);
  }

  onTransferTypeChange() {
    this.initBundlesInput(this.productInputTarget.selectedOptions[0].text);
    this.initNwscLocationsInput(this.productInputTarget.selectedOptions[0].text);
    this.initTransferTypeInput(this.transferTypeInputTarget.selectedOptions[0].value)
  }

  hideBanks() {
    const productInput = $("#contact_product_id");
    productInput.find('option').each((index, option) => {
      const optionText = option.text.toLowerCase();
      if (!optionText.includes('transfer')) {
        $(option).attr('hidden', false);
      } else {
        $(option).attr('hidden', true);
      }
    });
  }
  
  hideNonBanks() {
    const productInput = $("#contact_product_id");
    productInput.find('option').each((index, option) => {
      const optionText = option.text.toLowerCase();
      if (!optionText.includes('transfer')) {
        $(option).attr('hidden', true);
      } else {
        $(option).attr('hidden', false);
      }
    });
  }

  initTransferTypeInput(type){
    if(type == "mobile_money_payout"){
      $("#contact_product_id").parent().hide();
      $("#contact_bundle_id").parent().hide();
      $("#contact_contact_phone").parent().hide();
      $("#contact_amount").parent().show();
    } else if(type == 'bank_transfer_request'){
      this.hideNonBanks()
      this.showContactAndPhoneField()
    }else{
      this.hideBanks()
      this.showContactAndPhoneField()
    }
  }

  showContactAndPhoneField(){
    $("#contact_product_id").parent().show();
    $("#contact_contact_phone").parent().show();
  }

  initBundlesInput(product) {
    let escapedProduct = product.replace(
      /([ #;&,.+*~\':"!^$[\]()=>|\/@])/g,
      "\\$1"
    );
    let options = $(this.bundles)
      .filter(`optgroup[label="${escapedProduct}"]`)
      .html();

    if (options) {
      $("#contact_bundle_id").html(options);
      $("#contact_bundle_id").parent().show();
      $("#contact_amount").parent().hide();
    } else {
      // $("#contact_bundle_id").empty();
      $("#contact_bundle_id").parent().hide();
      $("#contact_amount").parent().show();
    }
  }

  initNwscLocationsInput(product){
    if(product == "National Water"){
      $("#contact_nwsc_location").parent().show();
    }else{
      $("#contact_nwsc_location").parent().hide();
    }
  }
}
