import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["uploadArea", "fileInput", "fileList"];

  connect() {}

  preventDrag(event) {
    event.preventDefault();
  }

  handleDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    this.fileInputTarget.files = files;
    this.handleFiles(files);
  }

  handleFiles(files) {
    const fileNames = Array.from(files).map((file) => file.name);
    this.fileListTarget.innerHTML = fileNames.join(", ");
  }

  handleFileInputChange() {
    const files = this.fileInputTarget.files;
    this.handleFiles(files);
    const fileNames = Array.from(files).map((file) => file.name);
    this.fileListTarget.innerHTML = fileNames.join(", ");
  }

  disconnect() {
    this.fileInputTarget.removeEventListener(
      "change",
      this.handleFileInputChange
    );
  }
}
