import PasswordVisibility from '@stimulus-components/password-visibility'

// Connects to data-controller="password-visibility"
export default class extends PasswordVisibility {
  connect() {
    super.connect()

  }
}

