import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fromWallet", "toWallet", "balance"];

  updateBalance() {
    const selectedWalletId = this.fromWalletTarget.value;
    if (selectedWalletId) {
      fetch(`/user/wallets/${selectedWalletId}/balance`)
        .then((response) => response.json())
        .then((data) => {
          this.balanceTarget.textContent = `Balance: ${data.balance}`;
        });
    }
  }

  filterWallets() {
    const selectedFromWalletId = this.fromWalletTarget.value;
    const options = Array.from(this.toWalletTarget.options);
    options.forEach((option) => {
      if (option.value === selectedFromWalletId) {
        option.hidden = true;
      } else {
        option.hidden = false;
      }
    });
  }
}
