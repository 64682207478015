import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tabs"
export default class extends Controller {
  connect() {
    console.log("Hello, Relworx!", this.element);
  }
  static targets = ["tab", "content"];

  connect() {
    this.showTab(this.tabTargets[0], this.contentTargets[0]); // Show the first tab on load
  }

  switchTab(event) {
    event.preventDefault();

    const tab = event.target;
    const contentId = tab.getAttribute("data-bs-target");
    const content = this.contentTargets.find((c) => `#${c.id}` === contentId);

    this.tabTargets.forEach((t) => t.classList.remove("active"));
    this.contentTargets.forEach((c) => c.classList.remove("show", "active"));

    this.showTab(tab, content);
  }

  showTab(tab, content) {
    tab.classList.add("active");
    content.classList.add("show", "active");
  }
}
