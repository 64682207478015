import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "source", "copyButton" ]

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.href)
    this.copyButtonTarget.innerHTML = "Copied!"
    this.copyButtonTarget.classList.remove('btn-info')
    this.copyButtonTarget.classList.add('btn-success')
  }
}
