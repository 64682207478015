import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["productInput"];

  connect() {
    this.bundles = $("#product_purchase_request_bundle_id").html();
    this.hideBanks()
    this.initBundlesInput(this.productInputTarget.selectedOptions[0].text);
    this.initNwscLocationsInput(this.productInputTarget.selectedOptions[0].text);
  }

  onProductChange() {
    this.initBundlesInput(this.productInputTarget.selectedOptions[0].text);
    this.initNwscLocationsInput(this.productInputTarget.selectedOptions[0].text);
  }

  hideBanks() {
    const productInput = $("#product_purchase_request_product_id");
    productInput.find('option').each((index, option) => {
      const optionText = option.text.toLowerCase();
      if (!optionText.includes('transfer')) {
        $(option).attr('hidden', false);
      } else {
        $(option).attr('hidden', true);
      }
    });
  }

  initBundlesInput(product) {
    let escapedProduct = product.replace(
      /([ #;&,.+*~\':"!^$[\]()=>|\/@])/g,
      "\\$1"
    );
    let options = $(this.bundles)
      .filter(`optgroup[label="${escapedProduct}"]`)
      .html();

    if (options) {
      $("#product_purchase_request_bundle_id").html(options);
      $("#product_purchase_request_bundle_id").parent().show();
      $("#product_purchase_request_amount").parent().hide();
    } else {
      // $("#product_purchase_request_bundle_id").empty();
      $("#product_purchase_request_bundle_id").parent().hide();
      $("#product_purchase_request_amount").parent().show();
    }
  }

  initNwscLocationsInput(product){
    if(product == "National Water"){
      $("#product_purchase_request_nwsc_location").parent().show();
    }else{
      $("#product_purchase_request_nwsc_location").parent().hide();
    }
  }
}
