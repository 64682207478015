import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['input', 'selectedId']

  static values = {
    url: String,
  }

  search() {
    const baseURL = this.urlValue
    const url = new URL(baseURL, window.location.origin)
    const params = new URLSearchParams(url.search)
    params.set('query', this.inputTarget.value)

    url.search = params.toString()
    const fullUrl = url.href

    fetch(fullUrl, { headers: { Accept: 'text/vnd.turbo-stream.html' } })
      .then((response) => {
        return response.text()
      })
      .then((html) => Turbo.renderStreamMessage(html))
      .catch((error) => error)
  }

  select(event) {
    const beneficiaryId = event.target.value
    $('#beneficiary_search_input').val(event.target.innerText.trim())
    this.selectedIdTarget.value = beneficiaryId
    $('#beneficiary_search_results ul').hide()
  }
}
